/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.EffectsBlockForm .expand {
  display: flex;
  margin: 0 6px 0 0;
  padding: 0;
}
.EffectsBlockForm .expand span {
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: center;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  width: 100%;
}
.EffectsBlockForm .rowFormControl {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 8px 0 0;
}
.EffectsBlockForm .formLabel {
  font-size: 1rem;
  font-weight: 700;
  justify-content: space-between;
  margin: 16px 0 0;
}
.EffectsBlockForm .formLabel span {
  color: #333;
  font-size: inherit;
  font-weight: inherit;
}
.EffectsBlockForm .collapse {
  padding: 0 8px;
}
.EffectsBlockForm .checkBox {
  margin-right: -8px;
}