/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.infoBoxTooltip[class*=" MuiTooltip"] {
  margin-left: 16px;
  margin-top: -8px;
  min-width: 150px;
  position: fixed;
  z-index: 100;
}
.infoBoxTooltip[class*=" MuiTooltip"] .tooltipText span {
  display: inline-block;
}

.infoBoxWrapper .infoBoxIcon {
  height: 18px;
  margin: 0 6px;
  vertical-align: middle;
  width: 18px;
}