/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.rootPanel {
  border-bottom: 1px solid #efefef;
  height: 40%;
  width: 100%;
}
@media only screen and (max-width : 1600px) {
  .rootPanel {
    height: 50%;
  }
}

.tabPanel {
  flex-grow: 1;
}
.tabPanel .tabs {
  border-bottom: 1px solid #efefef;
  box-shadow: none;
  padding: 0 24px;
}
.tabPanel .smallTitle {
  font-size: 1rem;
  padding: 16px 32px;
}
.tabPanel .imageVideo,
.tabPanel .placeholder {
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 11.2px;
}
.tabPanel .imageVideo .message,
.tabPanel .placeholder .message {
  border: 1px solid #efefef;
  border-left: 0 !important;
  padding: 10.4px;
}
.tabPanel .imageVideo .addImageVideo,
.tabPanel .imageVideo .addPlaceholder,
.tabPanel .placeholder .addImageVideo,
.tabPanel .placeholder .addPlaceholder {
  border: 1px solid #efefef;
  flex: 1;
}
.tabPanel .tab {
  min-width: auto;
  text-transform: inherit;
}
.tabPanel .tabBox {
  padding: 32px 0;
}
.tabPanel .tabBox .box {
  padding: 0;
}
.tabPanel .gridItem {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.tabPanel .btnLabel {
  flex-direction: column;
}
.tabPanel .btnRoot {
  align-self: start;
  border: 1px solid #efefef !important;
  border-radius: 0 !important;
  margin-bottom: 8px;
  padding: 12px;
}
.tabPanel .btnRoot:disabled {
  border: 1px solid #e0e0e0 !important;
}
.tabPanel .labelText {
  padding-right: 20%;
  width: 100%;
}
.tabPanel .labelText.disabled {
  opacity: 0.5;
}
.tabPanel .subTitle {
  padding: 0 32px;
}
.tabPanel .rotate {
  transform: rotate(90deg);
}