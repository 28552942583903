/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.Spinner {
  background-color: rgba(255, 255, 255, 0.54);
  border-top-color: #333;
  bottom: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.Spinner .donut {
  animation: spin infinite linear 1s;
  border: 0.2rem solid rgba(51, 51, 51, 0.3);
  border-radius: 50%;
  border-top-color: #333;
  height: 36px;
  left: calc(50% - 36px);
  position: absolute;
  top: calc(50% - 36px);
  width: 36px;
}
.Spinner.AuthLayout {
  margin-left: 240px;
  width: calc(100% - 240px);
}
@media screen and (max-width: 1279px) {
  .Spinner.AuthLayout {
    margin-left: auto;
    width: 100%;
  }
}