/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
@keyframes scaling-105 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
@keyframes scaling-110 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes scaling-115 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}
@keyframes scaling-120 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes scaling-125 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.25);
  }
}
@keyframes scaling-130 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
@keyframes scaling-135 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.35);
  }
}
@keyframes scaling-140 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}
@keyframes scaling-145 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.45);
  }
}
@keyframes scaling-150 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
.scaling-105 {
  animation: scaling-105 5s 0s 1 linear normal;
}

.scaling-110 {
  animation: scaling-110 5s 0s 1 linear normal;
}

.scaling-115 {
  animation: scaling-115 5s 0s 1 linear normal;
}

.scaling-120 {
  animation: scaling-120 5s 0s 1 linear normal;
}

.scaling-125 {
  animation: scaling-125 5s 0s 1 linear normal;
}

.scaling-130 {
  animation: scaling-130 5s 0s 1 linear normal;
}

.scaling-135 {
  animation: scaling-135 5s 0s 1 linear normal;
}

.scaling-140 {
  animation: scaling-140 5s 0s 1 linear normal;
}

.scaling-145 {
  animation: scaling-145 5s 0s 1 linear normal;
}

.scaling-150 {
  animation: scaling-150 5s 0s 1 linear normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
img,
video {
  z-index: 1;
}

.fadeIn::after,
.fadeOut::after {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  content: "";
  display: block;
}

.fadeIn img,
.fadeIn video {
  animation: fadeIn 1.5s 0s 1 linear normal;
  z-index: 2;
}
.fadeIn::after {
  z-index: 1;
}

.fadeOut {
  animation: fadeOut 1.5s 0s 1 linear normal;
  opacity: 0;
  z-index: 2;
}

.carousel {
  margin: 12px auto 0;
  position: relative;
}
.carousel .slide {
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel .whiteBg {
  background-color: #fff;
}
.carousel img.slideTag,
.carousel video.slideTag {
  height: 100%;
}
.carousel .SlideWrapper {
  background-color: #fff;
  inset: 0;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  position: absolute;
  width: 100%;
}