/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.checkbox {
  font-size: 1rem;
}
.checkbox span {
  font-size: 1rem;
  font-weight: 400;
}
.checkbox.labelPlacement {
  margin: 0;
}
.checkbox.labelBold span {
  font-weight: 700;
}
.checkbox .checkboxInput {
  max-height: 36px;
  max-width: 36px;
  padding: 8px;
}
.checkbox .checkboxInput:hover, .checkbox .checkboxInput:focus {
  background-color: transparent !important;
}
.checkbox .colorSecondary {
  color: #333 !important;
}
.checkbox .colorSecondary:hover, .checkbox .colorSecondary:focus {
  background-color: transparent !important;
}
.checkbox .colorSecondary.checked:hover, .checkbox .colorSecondary.checked:focus {
  background-color: transparent !important;
}
.checkbox.labelPlacementStart {
  margin: 0;
}
.checkbox.fullWidth {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.checkbox .marginRight {
  margin-right: -8px;
}