/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.borderColorPickerWrapper {
  align-items: start;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.borderColorPickerWrapper .formWrapper {
  margin-left: 24px;
  max-width: 23%;
  width: 100%;
}
@media (width <= 1400px) {
  .borderColorPickerWrapper .formWrapper {
    margin-left: 24px;
  }
}
@media (width <= 1200px) {
  .borderColorPickerWrapper {
    display: block;
  }
  .borderColorPickerWrapper .formWrapper {
    max-width: 30%;
  }
}
.borderColorPickerWrapper .borderWidth {
  margin: 8px 0 0;
}

.disabled {
  display: none !important;
}