/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.canvasComponent {
  position: relative;
}
.canvasComponent .savingOverlay {
  background-color: rgba(255, 255, 255, 0.54);
  inset: 0;
  position: absolute;
  z-index: 100;
}
.canvasComponent .overflow {
  overflow-x: hidden;
  overflow-y: auto;
}
.canvasComponent .overflowScroll {
  overflow-y: scroll;
}
.canvasComponent .overflowAuto {
  overflow-y: auto;
}
.canvasComponent .borderLeft {
  background-color: #fff;
  border-left: 1px solid #efefef;
}
.canvasComponent .borderRight {
  background-color: #fff;
  border-right: 1px solid #efefef;
}
.canvasComponent .canvas {
  border: 0;
  outline: 1px solid #333;
  z-index: 2;
}
.canvasComponent .smallTitle {
  font-size: 1rem;
}
.canvasComponent .smallTitle span {
  font-size: 1rem;
  font-weight: 700;
}
.canvasComponent .canvasBox {
  position: relative;
}