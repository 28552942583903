/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.renameForm {
  flex: 1 1 auto;
}
.renameForm .listItemText {
  display: flex;
  margin-top: 0;
}

.listItemTextHeight {
  margin: 0;
  padding-left: 10px;
}
.listItemTextHeight span {
  align-self: center;
  cursor: text;
  display: inline;
  font-size: 1rem;
}