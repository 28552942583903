/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.userProfile {
  display: flex;
  flex-direction: column;
}
.userProfile .formControl {
  margin-bottom: 20px;
}
.userProfile .fieldBox {
  margin: 20px 0;
}
.userProfile .btnBox {
  text-align: left;
}
.userProfile .btn {
  margin-bottom: 30px;
  min-width: 200px;
}