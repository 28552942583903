/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.processingQueueWrapper .List {
  border-radius: 4px;
  max-height: 384px;
  max-width: 384px;
  min-width: 384px;
  overflow-y: auto;
  position: relative;
  right: 4px;
  width: calc(100% - 4px);
}
.processingQueueWrapper .wrapperListHeader {
  position: relative;
}
.processingQueueWrapper .videoButton {
  background-color: #e83d3d;
}
.processingQueueWrapper .videoButton:hover, .processingQueueWrapper .videoButton:focus {
  background-color: #d81a1a;
}
.processingQueueWrapper .videoButton .videoIcon {
  color: #fff;
}
.processingQueueWrapper .ListHeader {
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  border-radius: 4px 4px 0 0;
  text-align: left;
  z-index: 3 !important;
}
.processingQueueWrapper .ListItem {
  border-bottom: 1px solid #efefef;
  flex-wrap: wrap;
}
.processingQueueWrapper .ListItem .itemRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.processingQueueWrapper .ListItem .itemRow h5 {
  word-break: break-all;
}
.processingQueueWrapper .ListItem .itemRow .itemBadge {
  margin-left: 8px;
  padding: 0 8px;
  text-transform: capitalize;
}
.processingQueueWrapper .ListItem .itemRow .marginLeft {
  margin-left: 16px;
}
.processingQueueWrapper .ListItem .textWrapper {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-flow: row nowrap;
  justify-items: flex-start;
  margin: 8px 0;
  width: 100%;
}
.processingQueueWrapper .ListItem .processing {
  position: relative;
  width: 100%;
}