@import 'variables';

/* Helper function
Return null rather than throwing an error if index is outside list range. */
@function nth-value($list, $index) {
    @return if(length($list) >= $index, nth($list, $index), null);
}

/* Different devices resolutions */
@mixin respond-to($media) {
    @if $media==old-phones {
        @media #{$xsmall-and-down} {
            @content;
        }
    }

    @else if $media==phones {
        @media #{$small-and-down} {
            @content;
        }
    }

    @else if $media==tablets-portrait {
        @media #{$medium-and-down} {
            @content;
        }
    }

    @else if $media==tablets-landscape {
        @media #{$large-and-down} {
            @content;
        }
    }

    @else if $media==desktops {
        @media #{$xlarge-and-down} {
            @content;
        }
    }

    @else if $media==desktops-large {
        @media #{$xxlarge-and-up} {
            @content;
        }
    }
}

/* Proper images aspect ratio - 0.5625 or 9/16 */
@mixin aspect-ratio($arglist...) {
    $map: keywords($arglist);
    $width: map-get($map, width) or nth-value($arglist, 1);
    $height: map-get($map, height) or nth-value($arglist, 2);
    $ratio: map-get($map, ratio) or
        if($width and $height, $width/$height, nth-value($arglist, 1)) or 1;
    $padding: calc(1 / #{$ratio} * 100%);

    &::before,
    &::after {
        clear: both;
        content: '';
        display: table;
        margin-left: -1px;
        width: 1px;
    }

    &::before {
        float: left;
        padding-bottom: $padding;
    }
}

@mixin fill-object() {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

@mixin overlay($overlay-color) {
    @include fill-object();

    background-color: $overlay-color;
    z-index: 10;
}

@mixin scale-animations() {
    $total: 10;

    @for $i from 1 through $total {
        @keyframes scaling-#{100 + $i*5} {
            0% {
                transform: scale(1);
            }

            100% {
                transform: scale(#{1 + $i * 0.05});
            }
        }
    }

    @for $i from 1 through $total {
        .scaling-#{100 + $i*5} {
            animation: scaling-#{100 + $i * 5} 5s 0s 1 linear normal;
        }
    }
}

@mixin auth-layout() {
    margin-left: $sidemenu-width;
    width: calc(100% - #{$sidemenu-width});

    @media screen and (max-width: 1279px) {
        margin-left: auto;
        width: 100%;
    }
}

@mixin input-style() {
    background-color: transparent;
    border: 0 none;
    line-height: 1.3rem;
    outline: none;
    padding: $spacing $spacing * 4 $spacing $spacing * 1.25;
}

@mixin form-control() {
    label[class*=' MuiInputLabel-outlined'] {
        font-weight: 500;
        transform: translate($spacing * 1.25, $spacing * 1.25) scale(1);

        &[class*=' MuiInputLabel-shrink'] {
            transform: translate($spacing * 1.25, $spacing * -0.75) scale(0.75);
        }
    }
    // Additional border width for selects
    .MuiSelect-select {
        background-color: $inverse-color;
        line-height: $spacing * 2.5;
        min-height: $spacing * 2.5;
        padding: $spacing $spacing * 4 $spacing $spacing * 1.25;
    }

    div[class*=' MuiOutlinedInput'],
    div[class*=' MuiTablePagination-input'] {
        &.MuiInputBase-root:not(.MuiAutocomplete-inputRoot) {
            height: $input-height;
        }

        &.MuiOutlinedInput-adornedEnd:not(.MuiAutocomplete-inputRoot) {
            padding-right: 0;
        }

        fieldset[class^='PrivateNotchedOutline'] {
            border-color: $border-color;
            border-radius: 0;
            padding: 0 $spacing * 0.625;
        }

        &:not([class*='Mui-disabled']) {
            &[class*=' Mui-focused'],
            &:hover {
                fieldset[class^='PrivateNotchedOutline'] {
                    border-color: $border-active-color;
                    border-width: 1px;
                }
            }
        }

        input {
            @include input-style();
        }

        .MuiInputAdornment-positionEnd button {
            padding: $spacing;

            &.MuiIconButton-edgeEnd {
                margin-right: 0;
            }
        }
    }
    // Helper
    .MuiFormHelperText-root {
        margin-left: $spacing * 1.25;
        margin-right: $spacing * 1.25;
    }
    // Icon
    .MuiSelect-icon {
        color: $primary;
        top: calc(50% - 10px);
    }
}
