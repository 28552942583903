/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.userMenu {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-right: 8px;
  position: relative;
}
.userMenu .logoutBtn {
  border-left: 1px solid #efefef;
  height: 72px;
  margin-left: 16px;
  margin-right: 0;
}
.userMenu .logoutBtn > span:first-of-type {
  max-width: 40px;
}

.userMenuList {
  border-radius: 4px;
  max-height: 384px;
  min-width: 168px;
  overflow-y: auto;
}
.userMenuList .userMenuListItem {
  align-items: center;
  display: flex;
  font-weight: 500;
  min-height: 48px;
}