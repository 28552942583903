/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.Login {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  height: calc(100% - 64px);
  justify-content: center;
}
.Login .form {
  display: flex;
  flex-direction: column;
  margin: 32px auto;
  padding: 0 10%;
  width: 100%;
}
.Login img {
  margin-bottom: 16px;
}
.Login .margin {
  margin: 8px;
}
.Login .gutterBottom {
  margin-bottom: 16px;
}
.Login .submitBtn {
  align-self: center;
  margin: 16px 0;
  max-width: 50%;
}
.Login .flexRow {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 10% 24px;
  max-width: 80%;
}
@media only screen and (width <= 421px) {
  .Login .flexRow {
    max-width: 100%;
  }
}
.Login .flexRow img {
  height: auto;
  max-width: 46%;
}
@media only screen and (width <= 421px) {
  .Login .loginText * {
    font-size: 1rem;
  }
}
.Login .link {
  color: #333;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.00938em;
  line-height: 1.75;
  min-width: initial;
  padding: 0 4px;
  text-decoration: underline;
  text-transform: initial;
}
@media only screen and (width <= 421px) {
  .Login .link {
    display: inline-block;
  }
}
.Login .link:hover {
  background-color: transparent;
  text-decoration: none;
}