/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.btn {
  border-radius: 0;
  color: #333;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn .textBtn {
  margin: 0 8px;
}
.btn .children {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.btn.defaultBtn {
  background-color: #e0e0e0;
  box-shadow: none;
  min-height: 36px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn.primaryBtn {
  background-color: #333;
  border: 0;
  box-shadow: none;
  color: #fff;
  margin: 0 8px;
  min-height: 36px;
  min-width: 120px;
  padding: 8px 16px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn.primaryBtn:hover {
  background-color: #333;
  border: 0;
  box-shadow: none;
  opacity: 0.8;
}
.btn.secondaryBtn {
  background-color: #efefef;
  box-shadow: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn.secondaryBtn:hover {
  background-color: #efefef;
  box-shadow: none;
  opacity: 0.8;
}
.btn.simpleBtn {
  align-self: start;
  background: none;
  border: 1px solid #333;
  box-shadow: none;
  flex: 0 0 auto;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 8px;
  min-height: 42px;
  overflow: visible;
  padding: 12px 9.6px;
  text-align: center;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn.simpleBtn:focus, .btn.simpleBtn:hover {
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.btn.simpleBtn:disabled {
  border: 1px solid #efefef !important;
}
.btn.smallBtn {
  line-height: 1.65;
  min-height: 36px;
  padding: 8px;
  white-space: nowrap;
}
@media only screen and (width <= 992px) {
  .btn.smallBtn {
    padding: 8px;
  }
}
@media only screen and (width <= 421px) {
  .btn.smallBtn {
    padding: 0 8px;
    white-space: initial;
  }
}
.btn.mediumBtn {
  min-height: 42px;
  padding: 12px 9.6px;
  white-space: nowrap;
}
@media screen and (width <= 992px) {
  .btn.mediumBtn {
    padding: 0 12px;
  }
}
.btn.largeBtn {
  padding: 20px 12px;
}
@media screen and (width <= 992px) {
  .btn.largeBtn {
    padding: 0 12px;
  }
}
.btn .onlyIcon {
  line-height: 0;
  margin: 0;
}
.btn .onlyIcon svg {
  font-size: 22px;
}
.btn.marginReset {
  margin: 0;
}
.btn.collapseBtn {
  align-self: start;
  background: none;
  border: 1px solid #d5d5d5 !important;
  box-shadow: none;
  color: #000;
  flex: 0 0 auto;
  font-size: 1rem;
  height: 24px;
  margin: 0;
  min-height: 24px;
  min-width: 24px;
  overflow: visible;
  padding: 0;
  text-align: center;
  width: 24px;
}
.btn.collapseBtn:focus, .btn.collapseBtn:hover {
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.btn.collapseBtn svg {
  font-size: 24px;
}