/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
[class*=MuiTooltip-tooltip].tooltip {
  background-color: #333;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  padding: 16px;
}
[class*=MuiTooltip-tooltip].tooltip span {
  display: block;
}