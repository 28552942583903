/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.StoryOptionsForm {
  border-bottom: 1px #efefef solid;
  padding: 16px;
}
.StoryOptionsForm .formControl {
  margin: 8px 0 0;
  text-align: left;
}
.StoryOptionsForm .formLabel {
  justify-content: space-between;
  margin: 8px 0 0;
}
.StoryOptionsForm .customDurationError {
  color: #333;
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 3px;
  text-align: left;
}