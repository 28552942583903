/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.categories {
  background-color: #fff;
  border-left: 1px solid #efefef;
  height: calc(100% - 204px);
  max-width: 240px;
  padding: 16px 0;
  position: absolute;
  right: 0;
  top: 68px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 100%;
  z-index: 9;
}
.categories .categoriesTop {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  max-height: 52px;
  padding: 0 16px;
  position: relative;
}
.categories .categoriesTop .searchForm {
  background-color: #fff;
  display: flex;
  flex: 0 0 auto;
  max-width: 100%;
  position: relative;
  width: auto;
  z-index: 2;
}
.categories .categoriesTop .search {
  margin: 0;
}
.categories .categoriesTop .search.open {
  width: 100%;
}
.categories .categoriesTop .button {
  font-weight: 500;
  padding: 0 16px;
  position: absolute;
  right: 16px;
  transition: visibility 300ms ease-in-out;
  visibility: visible;
  z-index: 1;
}
.categories .categoriesTop .button.hidden {
  pointer-events: none;
  visibility: hidden;
}
.categories .title {
  color: #333;
  opacity: 0.38;
  padding: 0 16px;
  text-align: left;
}
.categories .content {
  height: calc(100% - 52px);
  overflow-y: auto;
  padding-bottom: 8px;
}
.categories .content .categoriesMenu .navItem {
  display: block;
  position: relative;
}
.categories .content .categoriesMenu .navItem.active::before {
  background-color: #333;
  border-radius: 0 2px 2px 0;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 4px;
}
.categories .content .categoriesMenu .navItem.active .MuiListItem-button {
  background-color: rgba(0, 0, 0, 0.8);
}
.categories .content .categoriesMenu .navItem .menuButton {
  height: 24px;
  opacity: 1;
  padding: 0;
  position: absolute !important;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  z-index: 3;
}
.categories.filtersOpen {
  top: 120px;
}
.categories.filtersOpen .content {
  height: calc(100% - 104px);
}
.categories.noTemplates {
  height: calc(100% - 140px);
  transition: none;
}