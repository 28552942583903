/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.root {
  margin: 8px;
  padding: 16px;
}
.root .templatesList .menuList {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.root .templatesList .paper {
  border: 1px solid #efefef;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  z-index: 2;
}
.root .templatesList .paper .cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.root .templatesList .paper .content {
  background-color: #fff;
  max-height: 92px;
  padding: 16px;
  z-index: 2;
}
.root .templatesList .paper .content .templateTitle {
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  overflow: hidden;
  word-break: break-word;
}
.root .templatesList .paper .content .logo {
  display: block;
  height: auto;
  max-width: 40px;
}
.root .templatesList .paper::after {
  background-color: #efefef;
  inset: 0;
  content: "";
  display: block;
  position: absolute;
  transition: opacity 0.25s ease-out;
  z-index: 0;
}
.root .templatesList .paper:hover, .root .templatesList .paper.selected {
  cursor: pointer;
}
.root .templatesList .paper:hover::after, .root .templatesList .paper.selected::after {
  inset: -16px;
  transition: opacity 0.25s ease-out;
}
.root .templatesList .paper .templateCheck {
  background-color: rgba(255, 255, 255, 0.38);
  border-radius: inherit;
  height: 24px;
  margin: 0;
  opacity: 0.38;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  z-index: 9;
}
.root .templatesList .paper .templateCheck:hover {
  background-color: rgba(255, 255, 255, 0.38) !important;
}