/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.durationNotifications {
  align-items: center;
  background: #e83d3d;
  color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: all 0.25s linear;
  width: 100%;
}
.durationNotifications .text {
  height: 24px;
  line-height: 24px;
  width: auto;
}
.durationNotifications .notificationIcon {
  margin-right: 16px;
  transition: all 0.25s linear;
}
.durationNotifications.collapsed {
  transition: all 0.25s linear;
  width: 10%;
}
.durationNotifications.collapsed .text {
  opacity: 0;
  overflow: hidden;
  transition: all 0.25s linear;
  width: 0;
}
.durationNotifications.collapsed .notificationIcon {
  margin-right: 0;
  transition: all 0.25s linear;
}
.durationNotifications .btn {
  color: #fff;
}