.templates {
  height: 100%;
  position: relative;
}
.templates .box {
  position: relative;
}
@media screen and (width <= 1440px) {
  .templates .templatesContainer {
    max-width: 800px;
  }
}