/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.systemPage {
  display: flex;
  flex-direction: column;
}

.wrapper {
  border: 1px solid #efefef;
  box-shadow: none;
  margin: 0 0 16px;
  padding: 16px;
}
.wrapper:hover {
  box-shadow: 0 0 0 4px #efefef;
  transition: box-shadow 0.25s ease-out;
}
.wrapper .text {
  font-weight: normal;
  margin-bottom: 16px;
}
.wrapper .text::before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 9px;
  margin-bottom: 1px;
  margin-right: 8px;
  transition: all 1s;
  vertical-align: unset;
  width: 9px;
}
.wrapper .text .statusText {
  font-weight: 600;
}
.wrapper .caption {
  margin: 16px 0 0;
}
.wrapper .running::before {
  background-color: #000;
}
.wrapper .stopped::before {
  background-color: #e83d3d;
}
.wrapper .shutting-down::before,
.wrapper .stopping::before,
.wrapper .terminated::before,
.wrapper .pending::before {
  background-color: #ffa000;
}
.wrapper .button {
  font-weight: 500;
  margin-right: 16px;
  padding: 0 16px;
  transition: visibility 300ms ease-in-out;
}