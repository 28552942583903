/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.progressTimeline {
  display: flex;
  flex-basis: 100%;
  left: 50%;
  margin: 0 auto;
  padding: 8px 8px 0 8px;
  position: absolute;
  top: 16px;
  transform: translateX(-50%);
  width: 100%;
  z-index: 40;
}
.progressTimeline.dropShadow::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), transparent);
  inset: 0;
  content: "";
  display: block;
  height: 112px;
  position: absolute;
  width: 100%;
  z-index: 29;
}
.progressTimeline.dropShadowUpper {
  top: 8px;
}
.progressTimeline .progressSlide {
  background-color: rgba(255, 255, 255, 0.54);
  border: 0 none;
  border-radius: 2px;
  cursor: pointer;
  height: 1.6px;
  margin: 0 1.6px;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 39;
}
.progressTimeline .progressSlide::after {
  content: "";
  height: 30px;
  left: 0;
  position: absolute;
  top: -8px;
  width: 100%;
  z-index: 40;
}
.progressTimeline .clipArea {
  height: 1.6px;
  overflow: hidden;
}
.progressTimeline .innerProgress {
  background-color: rgba(255, 255, 255, 0.38);
  border: 0 solid #000;
  border-radius: 2px;
  height: 1.6px;
  pointer-events: none;
  width: 100%;
}
.progressTimeline .innerProgress * {
  pointer-events: none;
}
.progressTimeline .innerProgressBar {
  background-color: #fff;
  border-radius: 2px;
  height: 1.6px;
}