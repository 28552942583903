/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.transitionsPanel .title {
  display: flex;
  justify-content: space-between;
  line-height: 1rem;
}
.transitionsPanel .scaleUp {
  display: block;
}
.transitionsPanel .textTypingEffectEffect {
  display: block;
}
.transitionsPanel .switchRow {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.transitionsPanel .label {
  flex-basis: 100%;
  justify-content: space-between;
  margin-left: 0;
}
.transitionsPanel .collapse {
  padding: 0 8px;
}
.transitionsPanel h6 {
  margin-bottom: 0;
}

.collapseTitle {
  padding: 16px 0 8px;
}

.collapseTitle2 {
  padding: 0 0 8px;
}