/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.colorPicker {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  margin: 8px 0 0;
  position: relative;
  text-align: left;
  width: 100%;
}
.colorPicker .colorBox {
  border: 1px solid #efefef;
  box-sizing: border-box;
  display: inline-block;
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  pointer-events: none;
  width: 20px;
}
.colorPicker .colorFieldForm {
  margin: 0 16px 0 0;
  max-width: 224px;
}
.colorPicker .colorFieldForm .colorPickerInput {
  display: block;
  width: 100%;
}
.colorPicker .colorFieldForm .colorPickerInput input {
  padding: 8px;
}
@media (width <= 1400px) {
  .colorPicker .colorFieldForm {
    max-width: 160px;
  }
}
.colorPicker .transparency {
  font-weight: 500;
  margin-left: auto;
}
.colorPicker .transparency.left {
  margin-left: 0;
  margin-right: auto;
}
.colorPicker .transparency.center {
  margin-left: auto;
  margin-right: auto;
}
.colorPicker .transparency.right {
  margin-left: auto;
  margin-right: 0;
}
.colorPicker .eyedropperIcon {
  font-size: 1.8rem;
  margin-right: 8px;
  padding: 4px;
}
.colorPicker .eyedropperIcon.disabled svg {
  opacity: 0.38;
}

.pickerDialog {
  margin-bottom: 24px;
  position: absolute;
  top: 36px;
  z-index: 100;
}
.pickerDialog .sketchPicker {
  background-color: #fff;
  border-radius: 0 !important;
  border-style: none !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 8px !important;
}