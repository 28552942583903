/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.instagramMode .accountInfo {
  align-content: center;
  align-items: center;
  display: flex;
  left: 12px;
  position: absolute;
  top: 16px;
  z-index: 30;
}
.instagramMode .accountInfo .accountLogo {
  background-color: #fff;
  border-radius: 50%;
  display: block;
  height: 32px;
  object-fit: contain;
  padding: 2px;
  width: 32px;
}
.instagramMode .accountInfo .accountLogo.accountLogoGray {
  background: #333;
}
.instagramMode .accountInfo .accountName {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 32px;
  margin-left: 9.6px;
  margin-right: 9.6px;
}
.instagramMode .accountInfo .timePosted {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 32px;
  margin-right: 16px;
  opacity: 0.6;
}
.instagramMode .accountInfo img {
  width: 100%;
}
.instagramMode .sendMessageField {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 14.4px;
  position: absolute;
  width: 100%;
  z-index: 30;
}
.instagramMode .sendMessageField::after {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent);
  content: "";
  display: block;
  height: 112px;
  position: absolute;
  inset: auto auto 0;
  width: 100%;
  z-index: 29;
}
.instagramMode .sendMessageField img {
  position: relative;
  width: 100%;
  z-index: 30;
}
.instagramMode .sendMessageField .massageWrapper {
  border: 1px solid #fff;
  border-radius: 25px;
  display: flex;
  flex-grow: 8;
  justify-content: flex-start;
  padding: 4px;
  position: relative;
  z-index: 30;
}
.instagramMode .sendMessageField .takePhoto {
  background-color: rgba(255, 255, 255, 0.24);
  color: #fff;
  height: 32px;
  margin-right: 14.4px;
  position: relative;
  width: 32px;
  z-index: 30;
}
.instagramMode .sendMessageField .sendMessageInput {
  color: #fff;
  font-size: 1rem;
  position: relative;
  z-index: 30;
}
.instagramMode .sendMessageField .sendMessageInput input {
  /* stylelint-disable-next-line */
}
.instagramMode .sendMessageField .sendMessageInput input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1; /* Firefox */
}
.instagramMode .sendMessageField .sendMessageInput input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}
.instagramMode .sendMessageField .sendMessageButton {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 1.8rem;
  margin-left: 4px;
  margin-top: 4px;
  padding: 8px;
  position: relative;
  z-index: 30;
}
.instagramMode .sendMessageField .sendMessageButton:hover {
  background: none;
}
.instagramMode .sendMessageField .moreButton {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 1.8rem;
  padding: 8px;
  position: relative;
  z-index: 30;
}
.instagramMode .sendMessageField .moreButton:hover {
  background: none;
}