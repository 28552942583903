/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.noPointer {
  pointer-events: none;
}

.slideImg {
  display: block;
  height: inherit;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.PlayIcon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.PlayIcon .PlayIconBtn {
  background-color: rgba(255, 255, 255, 0.18);
}
.PlayIcon .PlayIconBtn:hover {
  background-color: rgba(255, 255, 255, 0.34);
}

.SlidePreloader {
  align-items: center;
  background-color: #fff;
  inset: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.number {
  bottom: -18px;
  font-size: 12px;
  height: 16px;
  left: auto;
  line-height: 14px;
  opacity: 0.5;
  position: absolute;
  right: 56px;
}