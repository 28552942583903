/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.filtersForm {
  padding-right: 16px;
  width: 100%;
}
.filtersForm .formControl {
  margin: 0 8px;
  min-width: 120px;
}
.filtersForm .filterButton {
  margin: 0 8px;
  padding: 0;
}
.filtersForm .filterTop {
  display: flex;
  margin: 0;
  width: 100%;
}
.filtersForm .filterDropdown {
  background-color: #fff;
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
  text-align: left;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 100%;
}
.filtersForm .filterDropdown.active {
  height: 52px;
  opacity: 1;
  padding-top: 16px;
}
.filtersForm .search {
  flex-basis: 33%;
  margin: 0 8px;
  max-width: 224px;
  padding: 0;
}