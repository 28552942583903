/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.button {
  border-radius: 0;
  color: #000;
  margin: 0;
  min-height: 145px;
  text-transform: initial;
  transition: background-color 0.2s;
  width: 50%;
}
.button:hover {
  border: 2px solid #000;
}
.button:hover .buttonMarked {
  display: none;
}
.button .buttonTitle {
  font-size: 20.8px;
  font-weight: 700;
  padding: 6px;
  position: relative;
}
.button .buttonSubTitle {
  font-size: 14.4px;
  padding: 6px;
  position: relative;
}
.button .contentCenter {
  display: block;
}
.button .contentCenter span {
  display: block;
}