/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.canvasPreview {
  height: 100%;
}

.canvas {
  border: 0;
  z-index: 1;
}

.standalone {
  position: relative;
  visibility: hidden;
}
.standalone.visible {
  background-color: #fff;
}

.slides {
  position: absolute;
  visibility: hidden;
}

.visible {
  user-select: none;
  visibility: visible;
}

.isLoading {
  visibility: hidden;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.appear-fade {
  animation: fadeIn 0s 0s 1 cubic-bezier(0.645, 0.16, 1, 0.7);
}

.disappear-fade {
  animation: fadeOut 0s 0s 1 cubic-bezier(0.215, 0.61, 0.355, 1);
}

.appear-directional {
  animation: slideIn 0s 0s 1 cubic-bezier(0.215, 0.61, 0.355, 1) normal;
}

.disappear-directional {
  animation: slideOut 0s 0s 1 cubic-bezier(0.215, 0.61, 0.355, 1);
}