/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.iconButton {
  padding: 0;
}
.iconButton.iconButtonBorder {
  border-radius: 0;
}
.iconButton.border {
  border: 1px solid #efefef;
  border-radius: 0;
}
.iconButton.smallIcon {
  border-radius: 0;
  height: 36px;
  width: 36px;
}
.iconButton.mediumIcon {
  height: 42px;
  width: 42px;
}
.iconButton.colorPrimary {
  background-color: #333;
}
.iconButton.colorPrimary:hover {
  background-color: #efefef;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.8;
}
.iconButton.colorSecondary {
  background-color: #efefef;
}
.iconButton.colorSecondary svg {
  fill: #fff;
}
.iconButton.colorSecondary:hover {
  background-color: #efefef;
  opacity: 0.8;
}