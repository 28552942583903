/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.popupDialog {
  padding: 24px 16px;
}
.popupDialog .title {
  padding: 0 0 16px;
}
.popupDialog .noSidePadding {
  padding-left: 0;
  padding-right: 0;
}

.templatesPopup {
  max-height: 640px !important;
  max-width: 800px !important;
  min-height: 240px !important;
  width: 100%;
}