/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.usersList {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  text-align: left;
}
.usersList .usersListItem {
  border: 1px solid #efefef;
  box-shadow: none;
  margin: 0 0 16px;
  padding: 0;
}
.usersList .usersListItem:hover {
  box-shadow: 0 0 0 4px #efefef;
  transition: box-shadow 0.25s ease-out;
}
.usersList .usersListItem > div {
  width: 100%;
}
.usersList .usersListItem p {
  margin: 0;
}
.usersList .userLink {
  color: #000;
  display: block;
  padding: 24px 20px;
  text-decoration: none;
}
.usersList .usersPosition {
  font-size: 1rem;
}
.usersList .usersPosition span {
  font-weight: 500;
}
.usersList .menuButton {
  float: right;
  margin: 24px 20px;
}
.usersList .logoWrapper {
  min-height: 32px;
}
.usersList .logoWrapper:not(:last-child)::after {
  content: " | ";
  display: inline-block;
  margin: 0 8px;
}
.usersList .logo {
  display: inline-block;
  max-width: 56px;
  vertical-align: middle;
}