/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.lockLayer.lockLayerButton {
  border-radius: 50%;
  border-style: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.lockLayer.lockLayerButton:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.lockLayer.lockLayerButton.lockLayerSelected {
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  color: rgba(0, 0, 0, 0.54);
  opacity: 1;
}
.lockLayer.lockLayerButton.lockLayerSelected:hover {
  background-color: rgba(0, 0, 0, 0.04);
}