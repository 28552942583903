/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.editorComponent .relative {
  position: relative;
}
.editorComponent .flex,
.editorComponent .overflow {
  display: flex;
  justify-content: center;
}
.editorComponent .overflow {
  overflow-x: hidden;
  overflow-y: auto;
}
.editorComponent .overflowScroll {
  overflow-y: auto;
}
.editorComponent .borderLeft {
  background-color: #fff;
  border-left: 1px solid #efefef;
}
.editorComponent .borderRight {
  background-color: #fff;
  border-bottom: 0;
  border-right: 1px solid #efefef;
}
.editorComponent .area {
  position: relative;
}
.editorComponent .rightPanel > div {
  width: 100%;
}
.editorComponent .SpinnerWrapper {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 99;
}
.editorComponent .SpinnerWrapper .Spinner {
  bottom: 0;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.editorComponent .storySlideOptions {
  height: 100%;
}
.editorComponent .flexColumn {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}
.editorComponent .flexColumn .additionalInfo {
  padding: 16px;
}