/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.slidesList {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.storyCarousel {
  flex-grow: 2;
  padding-top: 8px;
  position: relative;
}
.storyCarousel .canvasStory {
  margin: 0 !important;
}
.storyCarousel img,
.storyCarousel video {
  height: 100%;
}
.storyCarousel .hidden {
  animation: none !important;
  transition: none !important;
  visibility: hidden !important;
}
.storyCarousel .visible {
  user-select: none !important;
  visibility: visible !important;
}
.storyCarousel .SlideWrapper {
  inset: 0;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  position: absolute;
  width: 100%;
}