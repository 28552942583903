/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.root {
  border-bottom: 1px #efefef solid;
  padding: 16px 16px;
}
.root .smallTitle {
  font-size: 1rem;
  margin: 8px 0;
}
.root .smallTitle span {
  font-size: 1rem;
}
.root .gridItem {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.root .btnLabel {
  flex-direction: column;
}
.root .btnRoot {
  align-self: start;
  border: 1px solid #efefef !important;
  border-radius: 0 !important;
  margin-bottom: 8px;
  padding: 8px;
}
.root .btnRoot:disabled {
  border: 1px solid #e0e0e0 !important;
}
.root .labelText {
  padding-right: 20%;
  width: 100%;
}
.root .labelText.disabled {
  opacity: 0.5;
}
.root .rotate {
  transform: rotate(90deg);
}