/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.SlideStopOverlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  inset: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 100;
}
.SlideStopOverlay .replayBtn {
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 60px;
}
.SlideStopOverlay .replayText {
  color: #fff;
  font-weight: 500;
  text-shadow: 0.075em 0.08em 0.3em rgba(0, 0, 0, 0.8);
}