@import 'libs/shared/assets/src/lib/scss/variables';

.App {
    display: flex;
    height: 100%;
    text-align: center;
}

.content {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
}
