/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.Popup {
  padding: 0;
  position: relative;
}
.Popup .disabledBtn {
  border: 1px solid #e0e0e0;
}
.Popup .menuWrap {
  z-index: 100;
}
.Popup .menu {
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  left: -100%;
  margin-top: 16px;
  position: absolute;
  z-index: 100;
}
.Popup .menu.menuRight {
  left: 0;
  right: auto;
}
.Popup .menu.menuRight::after, .Popup .menu.menuRight::before {
  left: 26px;
  transform: translateX(-50%);
}
.Popup .menu.menuLeft {
  left: auto;
  right: 0;
}
.Popup .menu.menuLeft::after, .Popup .menu.menuLeft::before {
  right: 26px;
  transform: translateX(50%);
}
.Popup .menu.noArrow {
  margin-top: 8px;
}
.Popup .menu.noArrow::after, .Popup .menu.noArrow::before {
  display: none;
}
.Popup .menu:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.Popup .menu::after, .Popup .menu::before {
  border: solid transparent;
  bottom: 100%;
  content: "";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  width: 0;
}
.Popup .menu::after {
  border-bottom-color: rgba(0, 0, 0, 0.05);
  border-width: 10px;
  z-index: 100;
}
.Popup .menu::before {
  border-bottom-color: #fff;
  border-width: 8px;
  z-index: 101;
}