/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.noData {
  min-height: 440px;
  padding: 16px;
}

.SlidesLine {
  align-items: stretch;
  background-color: #fff;
  border-top: 1px solid #efefef;
  display: flex;
  flex-flow: row nowrap;
  grid-area: line;
  height: 140px;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
}
.SlidesLine .active {
  box-shadow: 0 0 0 3px #e83d3d;
}
.SlidesLine .TimeLineBtn {
  background-color: #3d3d3d;
  border-radius: 0;
  color: #fff;
  margin: 16px 8px;
  max-width: 64px;
  min-width: 64px;
}
.SlidesLine .TimeLineBtn::before, .SlidesLine .TimeLineBtn::after {
  clear: both;
  content: "";
  display: table;
  margin-left: -1px;
  width: 1px;
}
.SlidesLine .TimeLineBtn::before {
  float: left;
  padding-bottom: calc(1 / 0.5625 * 100%);
}
.SlidesLine .TimeLineBtn:hover {
  background-color: #3d3d3d;
}
.SlidesLine .TimeLineBtnLabel {
  flex-direction: column;
  height: 100%;
}
.SlidesLine .TimeLineBtnLabel > span:first-child {
  margin-bottom: 8px;
}
.SlidesLine .SlideBtn {
  height: 100px;
}
.SlidesLine .TimeLine {
  display: flex;
  flex-wrap: wrap;
}
.SlidesLine .SlidesLineRow {
  display: flex;
  flex-flow: row nowrap;
  padding-right: 8px;
}
.SlidesLine .TimeLineSlide {
  border: 0;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-basis: 0;
  justify-content: center;
  margin: 16px 4px 16px;
  outline: none;
  padding: 0;
  position: relative;
}
.SlidesLine .TimeLineSlide .Slide {
  border: 0;
  box-shadow: 0 0 0 1px rgba(51, 51, 51, 0.12);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin: 0;
  min-width: 64px;
  outline: none;
}
.SlidesLine .TimeLineSlide .Slide:hover {
  box-shadow: 0 0 0 3px rgba(51, 51, 51, 0.12);
}
.SlidesLine .TimeLineSlide .Slide .Outline {
  outline: none;
  padding: 16px;
}
.SlidesLine .TimeLineSlide .Slide .Outline::before, .SlidesLine .TimeLineSlide .Slide .Outline::after {
  clear: both;
  content: "";
  display: table;
  margin-left: -1px;
  width: 1px;
}
.SlidesLine .TimeLineSlide .Slide .Outline::before {
  float: left;
  padding-bottom: calc(1 / 0.5625 * 100%);
}
.SlidesLine .TimeLineSlide .DeleteSlide {
  background-color: rgba(255, 255, 255, 0.54);
  border-radius: 0;
  box-shadow: none;
  font-size: 1rem;
  height: 20px;
  min-width: 20px;
  padding: 0;
  position: absolute;
  right: 7px;
  top: 4px;
  z-index: 2;
}
.SlidesLine .TimeLineSlide .DeleteSlide:hover + .Outline .Slide {
  box-shadow: 0 0 0 3px rgba(51, 51, 51, 0.12);
}
.SlidesLine .TimeLineSlide .SlideTransition {
  align-self: center;
  cursor: default;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  min-width: 20px;
}
.SlidesLine .TimeLineSlide .SlideTransition > span:not(:last-child) {
  margin-bottom: 8px;
}
.SlidesLine .TimeLineSlide .SlideTransition .ArrowRoot {
  color: #999;
  margin: -10px;
}
.SlidesLine .TimeLineSlide .SlideTransition .transitionDuration {
  color: #999;
  font-size: smaller;
}