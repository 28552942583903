.ProgressLoader {
  flex-basis: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.ProgressLoader.relative {
  position: relative;
}
.ProgressLoader .colorSecondary {
  background-color: transparent;
}