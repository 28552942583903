/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.textParameters {
  text-align: left;
}
.textParameters .toggleButtons {
  margin-bottom: 8px;
  margin-right: 8px;
  width: auto;
}
.textParameters .toggleButtons .toggleBtn {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  height: 42px;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 42px;
}
.textParameters .toggleButtons .toggleBtn.toggleBtnSelected + .toggleBtn.toggleBtnSelected {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.textParameters .toggleButtons .toggleBtn svg {
  color: rgba(0, 0, 0, 0.54);
}
.textParameters .toggleButtons .toggleBtn.toggleBtnDisabled svg {
  color: rgba(0, 0, 0, 0.26);
}
.textParameters .toggleButtons .toggleBtn.toggleBtnDisabled svg path {
  fill: rgba(0, 0, 0, 0.26);
}
.textParameters .smallTitle {
  font-size: 1rem;
}
.textParameters .smallTitle span {
  font-size: 1rem;
  font-weight: 600;
}
.textParameters .formats {
  text-transform: capitalize;
}
.textParameters .textPanelForm {
  width: 100%;
}
.textParameters .paramsLabel {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin: 8px 0 12px;
  transform: none;
}