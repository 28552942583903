/* You can add global styles to this file, and also import other style files */
@import 'libs/shared/assets/src/lib/scss/variables';
@import 'libs/shared/assets/src/lib/scss/mixins';

/* MATERIAL ICONS FONT */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: normal;
    src: url('libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.eot');
    src:
        url('libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'),
        url('libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

/* OPUS FONTS */
@font-face {
    font-display: swap;
    font-family: 'Nobel Light';
    font-style: normal;
    font-weight: 300;
    src:
        url('libs/shared/assets/src/lib/fonts/Nobel-Light.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/Nobel-Light.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/Nobel-Light.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Nobel Regular';
    font-style: normal;
    font-weight: normal;
    src:
        url('libs/shared/assets/src/lib/fonts/Nobel-Regular.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/Nobel-Regular.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/Nobel-Regular.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Nobel Bold';
    font-style: normal;
    font-weight: bold;
    src:
        url('libs/shared/assets/src/lib/fonts/Nobel-Bold.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/Nobel-Bold.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/Nobel-Bold.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Avenir 100';
    font-style: normal;
    font-weight: 100;
    src:
        url('libs/shared/assets/src/lib/fonts/Avenir-100.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/Avenir-100.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/Avenir-100.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Avenir 300';
    font-style: normal;
    font-weight: 100;
    src:
        url('libs/shared/assets/src/lib/fonts/Avenir-300.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/Avenir-300.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/Avenir-300.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Avenir 500';
    font-style: normal;
    font-weight: 100;
    src:
        url('libs/shared/assets/src/lib/fonts/Avenir-500.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/Avenir-500.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/Avenir-500.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Avenir 700';
    font-style: normal;
    font-weight: 100;
    src:
        url('libs/shared/assets/src/lib/fonts/Avenir-700.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/Avenir-700.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/Avenir-700.ttf') format('truetype');
}

/* SOMEDAY FONTS */

@font-face {
    font-display: swap;
    font-family: 'Museo Sans 100';
    font-style: normal;
    font-weight: 100;
    src:
        url('libs/shared/assets/src/lib/fonts/MuseoSans-100.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-100.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-100.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Museo Sans 300';
    font-style: normal;
    font-weight: 300;
    src:
        url('libs/shared/assets/src/lib/fonts/MuseoSans-300.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-300.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-300.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Museo Sans 500';
    font-style: normal;
    font-weight: 500;
    src:
        url('libs/shared/assets/src/lib/fonts/MuseoSans-500.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-500.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-500.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Museo Sans 700';
    font-style: normal;
    font-weight: 700;
    src:
        url('libs/shared/assets/src/lib/fonts/MuseoSans-700.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-700.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/MuseoSans-700.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Trade Gothic Condensed';
    font-style: normal;
    font-weight: 700;
    src:
        url('libs/shared/assets/src/lib/fonts/TradeGothic-Bold.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/TradeGothic-Bold.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/TradeGothic-Bold.ttf') format('truetype');
}

// SFProText
//// Titles
//@font-face {
//    font-family: 'SFProText';
//    font-style: normal;
//    font-weight: 600;
//    src: url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-semibold.eot');
//    src: url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-semibold.svg#sf_pro_textsemibold')
//            format('svg'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-semibold.eot?#iefix')
//            format('embedded-opentype'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-semibold.woff') format('woff'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-semibold.ttf') format('truetype');
//}
//// Subtitles
//@font-face {
//    font-family: 'SFProText';
//    font-style: normal;
//    font-weight: 500;
//    src: url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-medium.eot');
//    src: url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-medium.svg#sf_pro_textmedium') format('svg'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-medium.eot?#iefix')
//            format('embedded-opentype'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-medium.woff') format('woff'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-medium.ttf') format('truetype');
//}
//// Regular
//@font-face {
//    font-family: 'SFProText';
//    font-style: normal;
//    font-weight: 400;
//    src: url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-regular.eot');
//    src: url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-regular.svg#sf_pro_textregular')
//            format('svg'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-regular.eot?#iefix')
//            format('embedded-opentype'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-regular.woff') format('woff'),
//        url('libs/shared/assets/src/lib/fonts/SFPro/sf-pro-text-regular.ttf') format('truetype');
//}

@font-face {
    font-display: swap;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    src: url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.eot');
    src:
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.eot?#iefix')
        format('embedded-opentype'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.ttf') format('truetype'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.svg#SFProDisplay-Regular')
        format('svg');
}

@font-face {
    font-display: swap;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: 500;
    src: url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.eot');
    src:
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.eot?#iefix')
        format('embedded-opentype'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.ttf') format('truetype'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.svg#SFProDisplay-Medium')
        format('svg');
}

@font-face {
    font-display: swap;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: 600;
    src: url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.eot');
    src:
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.eot?#iefix')
        format('embedded-opentype'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.woff2') format('woff2'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.woff') format('woff'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.ttf')
        format('truetype'),
        url('libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.svg#SFProDisplay-Semibold')
        format('svg');
}

html {
    font-size: $spacing * 1.75;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

*:focus,
*:hover {
    outline: none;
}

#root {
    height: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
    display: none;
    height: 0;
    width: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pointer {
    cursor: pointer;
    user-select: none;
}

.hidden {
    display: none;
    left: -9999px;
    position: absolute;
    top: -9999px;
    visibility: hidden;
}

.visuallyHidden {
    visibility: hidden;
}

.Visible {
    visibility: visible !important;
}

.Dimmed {
    opacity: 0.6;
}

.noData {
    color: $mid-gray;
    padding: $spacing * 6 0;
}

.noSearchData {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.no-scroll {
    overflow-y: hidden;
}

.text-capitalize {
    text-transform: capitalize;
}

.canvas-container {
    background-color: $inverse-color;
    margin: $spacing * 2 auto;
    position: relative;
    user-select: none;
}

// React Dropzone styles
.dropzone {
    @include fill-object();

    z-index: 2;
}

.activeDrop {
    z-index: 9;

    &::after {
        @include overlay($white-opacity);

        align-items: flex-start;
        border: 3px dashed $border-color;
        content: "Drag 'n' drop file for upload";
        display: flex;
        font-size: $spacing * 1.25;
        justify-content: center;
    }
}

.Preloader {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.flexRow,
%flexrow {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.flexColumn {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.responsiveWarning {
    display: none;

    @media only screen and (width <= 421px) {
        align-self: center;
        background: rgb(255 255 255 / 95%);
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5%;
        position: fixed;
        z-index: 999;
    }
}

.alignStart {
    align-items: flex-start;
}

.smallTitle {
    font-size: $spacing * 2;

    span {
        font-size: $spacing * 2;
        font-weight: 700;
    }
}

//Icon button
button.opusIconBtn {
    border: 1px solid $border-color;
    border-radius: 0;
    height: $icon-height;
    margin-left: $spacing;
    margin-right: $spacing;
    padding: 0;
    width: $icon-height;
}

// Global Form Fields Style
.formControl {
    @include form-control();

    font-size: $app-font-size;
    font-weight: 500;
    text-align: left;

    &.MuiFormControl-root {
        margin: $spacing * 2 0 0;
    }
}

// Filters form
.filtersSelectField {
    @include form-control();

    flex-basis: 33%;
    margin: 0 $spacing !important;
    max-width: $spacing * 28;
}

// Autocomplete Selected Item override
div[class^='MuiAutocomplete-popper'] {
    ul[class^='MuiAutocomplete-listbox'] li[aria-selected='true'] {
        background-color: $white;
    }
}

// Mui Checkbox
.Mui-disabled input[type='checkbox'] + svg {
    opacity: 0.38;
}

.boxBorder,
%boxborder {
    border-bottom: 1px solid $border-color;
    padding: $spacing * 2;
}

/* Fix for Fabric.js text */
textarea[data-fabric-hiddentextarea] {
    position: fixed !important;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
    width: $spacing;
}

/* Track */
::-webkit-scrollbar-track {
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-color;
    border-radius: $spacing * 0.5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover-color;
}

.logoWrapper {
    align-items: center;
    display: flex;
    min-height: $spacing * 6;

    .logo {
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0 $spacing * 2 0 0;
        max-width: $spacing * 14;
        vertical-align: middle;
    }
}

/*** TRANSITIONS ***/

/*** Use :global scoped keyframes for using inside SCSS modules ***/
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* FADE */
.fade-enter,
.fade-leave {
    opacity: 0.01;
}

.fade-enter.fade-enter-active,
.fade-leave {
    opacity: 1;
}

.fade-enter.fade-enter-active,
.fade-leave.fade-leave-active {
    transition: opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
}

// New React transition classes
.canvas-fade-enter {
    opacity: 0;
}

.canvas-fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.canvas-fade-exit {
    opacity: 1;
}

.canvas-fade-exit-active {
    opacity: 0;
    transition: opacity 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* DIRECTIONAL */
.directional-enter,
.directional-leave {
    transform: translateY(100%);
}

.directional-enter.directional-enter-active,
.directional-leave.directional-leave-active {
    transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.directional-enter.directional-enter-active,
.directional-leave {
    transform: translateY(0);
}

.directional-leave.directional-leave-active {
    transform: translateY(-100%);
}

// New React transition classes
.canvas-directional-enter {
    transform: translateY(100%);
}

.canvas-directional-enter-active {
    transform: translateY(0%);
    transition: transform 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.canvas-directional-exit {
    transform: translateY(0%);
}

.canvas-directional-exit-active {
    transform: translateY(-100%);
    transition: transform 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.spaceAround {
    display: flex;
    justify-content: space-around;
}
