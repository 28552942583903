/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.MainSlide {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 8px;
  position: relative;
}

.preview {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: initial;
  padding: 0;
  position: relative;
  width: auto;
}
.preview .number {
  bottom: 8px;
  left: 0;
  position: absolute;
}
.preview .AddSlideBtn {
  border-radius: initial;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin: 8px;
  position: absolute;
  right: -64px;
}
.preview .AddSlideBtn .AddSlideIcon {
  font-size: 40px;
}