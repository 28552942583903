/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.usersListPage {
  display: flex;
  flex-direction: column;
}

.createUserBtnWrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.filtersField {
  flex-basis: 33%;
  margin: 0 8px !important;
}
.filtersField div fieldset {
  border-radius: 0;
}

.noUsersSearch {
  margin-top: calc(100% - 204px);
  transform: translateY(-50%);
}