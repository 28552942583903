/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.categoriesPickerForm {
  min-width: 200px;
  position: absolute;
  right: 16px;
  top: 24px;
  width: auto;
}
.categoriesPickerForm .formControl {
  margin: 0 8px;
  width: 100%;
}

.categoriesPickerPopover ul li[class*=" Mui-selected"]::after {
  content: "\e876";
  display: block;
  font-family: "Material Icons", serif;
  font-size: 16px;
  height: 16px;
  line-height: 1;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}