.slideLogo {
  bottom: 4%;
  left: 36%;
  max-width: 28%;
  position: absolute;
}

.somedayLogo.bottom {
  bottom: 3.2%;
  left: 35%;
  max-width: 30%;
}
.somedayLogo.center {
  top: 43%;
  left: 15%;
  max-width: 70%;
}
.somedayLogo.top {
  top: 28%;
  left: 15%;
  max-width: 70%;
}

.opusLogo.center {
  top: 46%;
  left: 15%;
  max-width: 67%;
}
.opusLogo.top {
  top: 30%;
  left: 15%;
  max-width: 67%;
}

.opuspantsLogo.bottom {
  bottom: 5%;
  left: 28%;
  max-width: 44%;
}
.opuspantsLogo.center {
  top: 49%;
  left: 12%;
  max-width: 76%;
}
.opuspantsLogo.top {
  top: 33%;
  left: 12%;
  max-width: 76%;
}