/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.notifications .notificationsListItem {
  border-bottom: 1px solid #efefef;
  margin: 0;
  padding: 20px 25px;
  position: relative;
}
.notifications .notificationsListItem:last-child {
  border: 0;
}
.notifications .notificationsListItem ul {
  padding: 0;
}
.notifications.notificationPopup {
  border-radius: 4px;
  list-style: none;
  margin: 0;
  max-height: 592px;
  min-width: 496px;
  overflow-y: auto;
  padding: 0;
  position: relative;
  right: 4px;
  text-align: left;
  top: 100%;
  width: calc(100% - 4px);
}
@media only screen and (max-width : 1600px) {
  .notifications.notificationPopup {
    max-height: 512px;
    min-width: 448px;
  }
}
.notifications.notificationPopup .notificationsListItem {
  padding: 20px 50px;
}
.notifications .notificationsDate {
  color: #b0b0b0;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 20px;
  position: relative;
  transition: all 2s;
}
.notifications .notificationsDate::before {
  border-radius: 50%;
  content: "";
  height: 9px;
  left: -20px;
  position: absolute;
  top: 2px;
  transition: all 2s;
  width: 9px;
}
.notifications .unRead::before {
  background-color: #000;
}
.notifications .unRead.error::before {
  background-color: #e83d3d;
}
.notifications .errorText {
  color: #e83d3d;
}
.notifications .notificationsBtn {
  text-align: right;
}
.notifications .notificationsStatus {
  font-size: 1rem;
}