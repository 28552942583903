/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.dialog {
  padding: 24px 16px;
}
.dialog .title {
  padding: 0 0 16px;
}
.dialog .noSidePadding {
  padding-left: 0;
  padding-right: 0;
}
.dialog .form .gutterBottom {
  margin: 0 0 16px;
  text-align: left;
}