/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.fullWidth {
  width: 100%;
}

.selectInput {
  position: relative;
}
.selectInput .expand {
  cursor: pointer;
  display: flex;
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
}
.selectInput .expand span {
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}
.selectInput .expandOpen {
  transform: rotate(-180deg);
}
.selectInput .dropdownInput {
  width: 100%;
  z-index: 9;
}
.selectInput .dropdownInput .dropdownInputMenu {
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  max-height: 250px;
  overflow-y: scroll;
  width: 100%;
}
.selectInput .dropdownInput ul {
  padding: 8px 0;
  width: 100%;
}

.selectMenuPaper {
  border-radius: 0 !important;
}
.selectMenuPaper .selectMenuList li {
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 16px;
}
.selectMenuPaper .selectMenuList li span {
  font-size: inherit;
  font-weight: inherit;
}
.selectMenuPaper .selectMenuList li > label {
  margin-right: 0;
}

.autoComplete .chip {
  background-color: #efefef;
  border-radius: 0;
}
.autoComplete .deleteIcon {
  color: #333;
}
.autoComplete .deleteIcon:hover {
  color: #000;
}

.listItem {
  align-items: initial;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  margin: 4px 0;
  min-width: 0;
}
.listItem .checkIcon {
  color: #333;
}

.searchField {
  display: flex;
  justify-content: flex-start;
}
.searchField div.searchInput {
  min-width: 36px;
  overflow: hidden;
  transition: all 200ms cubic-bezier(0.4, 0.5, 0.6, 1) 0ms;
  width: 36px;
}
.searchField div.searchInput input[type=search] {
  padding: 8px 0;
}
.searchField div.searchInput input[type=search] + div {
  margin-left: 0;
}
.searchField div.searchInput label {
  opacity: 0;
}
.searchField div.searchInput.showInput {
  display: block;
  flex-basis: 100%;
  overflow: visible;
  transition: all 200ms cubic-bezier(0.13, 0.23, 0.49, 0.96) 0ms;
  width: 180px;
}
.searchField div.searchInput.showInput label {
  opacity: 1;
}
.searchField div.searchInput.showInput input[type=search] {
  padding: 8px 32px 8px 10px;
}
.searchField div.searchInput.showInput input[type=search] + div {
  margin-left: 0;
}

.checkBox {
  max-height: 36px;
  max-width: 36px;
  padding: 8px;
}
.checkBox:hover, .checkBox:focus {
  background-color: transparent !important;
}