/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 72px);
}
.Wrapper .SlideWrapper {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
}
.Wrapper .Preloader {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  height: calc(100vh - 140px);
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.Wrapper .iconSmall {
  font-size: 20px;
}
.Wrapper .button {
  margin: 8px;
}

.BlackBackground {
  background: rgba(0, 0, 0, 0.8);
}