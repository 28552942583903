/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.innnerContainer {
  align-content: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.pagination {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #efefef;
  bottom: 0;
  box-shadow: none;
  display: flex;
  flex-flow: row wrap;
  height: 64px;
  justify-content: flex-end;
  left: 0;
  padding-top: 0;
  position: fixed;
  width: 100%;
  z-index: 11;
}
.pagination .selectRoot > div {
  background-color: #fff;
  border: 1px solid #efefef;
  line-height: 20px;
  max-width: 56px;
  min-height: 20px;
}
.pagination .selectRoot > div:hover, .pagination .selectRoot > div:focus {
  border-color: #999;
}
.pagination .selectRoot input {
  background-color: transparent;
  border: 0 none;
  line-height: 1.3rem;
  outline: none;
  padding: 8px 32px 8px 10px;
}
.pagination .caption {
  color: #000;
  display: block;
  font-size: 1rem;
  height: 2rem;
  line-height: 2.1rem;
  margin: 0 15px;
}

.paginationNav {
  align-items: center;
  display: flex;
}

.paginationNavItem {
  color: #b0b0b0;
  cursor: pointer;
  display: block;
  height: 34px;
  line-height: 34px;
  width: 34px;
}
.paginationNavItem:hover {
  color: #000;
}
.paginationNavItem.active {
  font-weight: 700;
}

.selectMenuPaper {
  border-radius: 0 !important;
}
.selectMenuPaper .selectMenuList li {
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 16px;
}
.selectMenuPaper .selectMenuList li span {
  font-size: inherit;
  font-weight: inherit;
}
.selectMenuPaper .selectMenuList li > label {
  margin-right: 0;
}