/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.Spinner {
  align-items: center;
  border-top-color: #333;
  bottom: 0;
  display: flex;
  flex-basis: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99;
}
.Spinner .donut {
  animation: spin infinite linear 1s;
  border: 0.2rem solid rgba(51, 51, 51, 0.3);
  border-radius: 50%;
  border-top-color: #333;
  height: 36px;
  width: 36px;
}
.Spinner .donut.inverse {
  border-top-color: #fff;
}
.Spinner.small .donut {
  border: 0.1rem solid rgba(51, 51, 51, 0.3);
  border-top-color: #333;
  height: 21.6px;
  width: 21.6px;
}
.Spinner.absolute {
  position: absolute;
}
.Spinner.fixed {
  position: fixed;
  z-index: 10;
}
.Spinner.fixed .donut {
  margin-left: -54px;
}
.Spinner.fixed.small .donut {
  margin-left: -21.6px;
}
.Spinner.overlay {
  background-color: rgba(255, 255, 255, 0.54);
}
.Spinner.opaque {
  background-color: #fff;
}