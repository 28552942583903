/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.userNotificationsList .wrapperShowAll {
  position: relative;
}
.userNotificationsList .showAll {
  background: #fff;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #efefef;
  bottom: 0;
  padding: 20px 58px 20px 0;
  position: relative;
  width: 100%;
}
.userNotificationsList .notificationsBtn {
  text-align: right;
}
.userNotificationsList .badge {
  background-color: #e83d3d;
  border-radius: 0;
  color: #fff;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
}
.userNotificationsList .badge.anchorOriginTopRightRectangle {
  right: 0;
  top: 0;
}