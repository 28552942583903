/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.menuList[class*=" MuiPopover-paper"] {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  min-height: 0;
  min-width: 0;
  padding: 0;
}

.templatesList .paper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: auto;
}
.templatesList .paper .cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
}
.templatesList .paper .cover .infoOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: -44px;
  height: 44px;
  padding: 8px;
  position: absolute;
  transition: bottom 0.5s;
  width: 100%;
  z-index: 2;
}
.templatesList .paper .cover .infoOverlay.noCategories {
  height: 32px;
}
.templatesList .paper .cover .infoOverlay.noCategories svg {
  vertical-align: bottom;
}
.templatesList .paper .cover .infoOverlay .infoOverlayBox {
  flex: 1 1 auto;
}
.templatesList .paper .cover .infoOverlay .caption {
  color: #999;
  letter-spacing: -0.5px;
  line-height: 14px;
}
.templatesList .paper .menuButton {
  background-color: rgba(255, 255, 255, 0.38);
  height: 24px;
  opacity: 0;
  padding: 0;
  position: absolute !important;
  right: 8px;
  top: 8px;
  transition: opacity 0.5s;
  width: 24px;
  z-index: 3;
}
.templatesList .paper:hover {
  box-shadow: 0 0 0 4px #efefef;
  transition: box-shadow 0.25s ease-out;
}
.templatesList .paper:hover .infoOverlay {
  bottom: 0;
}
.templatesList .paper:hover .menuButton {
  opacity: 1;
}
.templatesList .paper .verticalDivider {
  line-height: 1;
  padding: 0 3px 0 5px;
}
.templatesList .paper .content {
  background-color: #fff;
  padding: 16px 8px;
  position: relative;
  z-index: 2;
}
.templatesList .paper .content .templateTitle {
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  margin-top: 8px;
  word-break: break-word;
}
.templatesList .paper .content .statusTitle {
  color: #999;
}
.templatesList .paper .content .statusTitle.draftStatus {
  color: #e83d3d;
}
.templatesList .paper .content .logoImg {
  display: block;
  height: auto;
  max-width: 40px;
  width: 100%;
}
.templatesList .paper.paperLock {
  cursor: not-allowed;
}
.templatesList .paper.paperLock:hover * {
  transition: none;
}