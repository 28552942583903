/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.zoomPreviewWrapper {
  background-color: #fff;
  border: 2px solid #999;
  border-radius: 50%;
  height: 160px;
  overflow: hidden;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 160px;
  z-index: 100;
}
.zoomPreviewWrapper .zoomPreview {
  border: 0 none;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
}
.zoomPreviewWrapper .zoomPreview::after {
  border: 2px solid #c5d9ff;
  content: "";
  display: block;
  height: 10px;
  left: 74px;
  position: absolute;
  top: 74px;
  width: 10px;
  z-index: 101;
}
.zoomPreviewWrapper .zoomPreview .grid {
  background-image: url("libs/shared/assets/src/lib/images/grid.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100%;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 101;
}
.zoomPreviewWrapper .zoomPreview .colorContainer {
  background: #3d3d3d;
  bottom: 21px;
  color: #fff;
  height: 24px;
  left: 0;
  line-height: 22px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  width: 83px;
  z-index: 101;
}
.zoomPreviewWrapper .zoomPreview .canvasPreview {
  position: absolute;
  z-index: 100;
}