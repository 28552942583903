/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.carousel {
  background-color: rgba(0, 0, 0, 0.8);
  flex-grow: 2;
  flex-shrink: 1;
  height: 100%;
  padding-top: 8px;
  position: relative;
  width: 100%;
}
.carousel > div {
  margin: 0 auto;
  top: 0;
}