/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.actionBar {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-shadow: none;
  display: flex;
  flex-flow: row wrap;
  grid-area: actionbar;
  justify-content: space-between;
  min-height: 68px;
  padding: 16px 0;
  position: relative;
  width: 100%;
  z-index: 11;
}
.actionBar .actionBarWrap {
  align-items: flex-start;
  display: flex;
  flex-basis: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 8px;
}
@media screen and (width <= 600px) {
  .actionBar .actionBarWrap {
    flex-wrap: wrap;
    max-width: 100%;
  }
}
.actionBar .btnSection {
  min-width: 35%;
}
.actionBar .btnSection.leftAlign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.actionBar .btnSection.rightAlign {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.actionBar .buttonBack {
  background: none;
  box-shadow: none;
  margin: 0;
  min-width: 36px;
  padding: 4px;
}
.actionBar .buttonBack:hover {
  background: none;
  box-shadow: none;
}
.actionBar .instagramModeCheckbox {
  margin-right: 16px;
}