/* Colors */
$black: #000;
$white: #fff;
$black-opacity: rgba(0, 0, 0, 0.54);
$white-opacity: rgba(255, 255, 255, 0.54);
$white-opacity-middle: rgba(255, 255, 255, 0.38);
$white-opacity-lite: rgba(255, 255, 255, 0.24);
$black-middle-opacity: rgba(0, 0, 0, 0.8);
$black-opacity-lite-hex: rgba(0, 0, 0, 0.04);
$active-black: #3d3d3d;
$gray: #e0e0e0;
$light-gray: #fafafa;
$light-black: #999;
$dark-gray: #333;
$semi-gray: #efefef;
$mid-gray: #d5d5d5;
$hard-gray: #b0b0b0;
$dusty-gray: #999;
$green: #43a047;
$orange: #ffa000;
$red: #e83d3d;
$medium: #8d8d8d;
$outer-border: rgba(51, 51, 51, 0.12);

/* Theme colors */
$secondary: $semi-gray;
$primary: $dark-gray;
$default: $white;
$success: $green;
$warning: $orange;
$error: $red;
$accent: $red;
$inverse-color: $white;
$opaque: $white;

/* Sizes */
$spacing: 8px;
$sidemenu-width: $spacing * 30;
$action-bar-height: $spacing * 8.5;
$pagination-height: $spacing * 8;
$header-height: $spacing * 9;
$filters-height: $spacing * 6.5;
$slidesline-height: $spacing * 17.5;
$app-font-size: 1rem;
$categories-size: $spacing * 30;
$button-small: $spacing * 4.5;
$button-medium: $spacing * 5.25;
$icon-small: $spacing * 4.5;
$icon-medium: $spacing * 5.25;
$input-height: $spacing * 4.5;
$icon-height: $spacing * 4.5;
$arrow-height: $spacing * 1.25;
$spinner-size: $spacing * 4.5;

/* Theme colors */
$bg-color: #f5f5f5;
$default-bg: $light-gray;
$border-color: $secondary;
$btn-border-color: $primary;
$border-active-color: $dusty-gray;
$text-tertiary: $dusty-gray;

$text-color: $primary;
$scrollbar-thumb-color: $gray;
$scrollbar-thumb-hover-color: $secondary;

/* Shadows */
$list-item-shadow: 0 0 0 $spacing * 0.5 $secondary;
$elevation-1: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
$elevation-2: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
$elevation-4: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
$elevation-5: 0 2px 4px -1px rgba(245, 0, 87, 0.2), 0 4px 5px 0 rgba(245, 0, 87, 0.14),
    0 1px 10px 0 rgba(245, 0, 87, 0.12);

/* Animation */
$transition-background-color: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
$transition-cubic-bezier-in: all 200ms cubic-bezier(0.13, 0.23, 0.49, 0.96) 0ms;
$transition-cubic-bezier-out: all 200ms cubic-bezier(0.4, 0.5, 0.6, 1) 0ms;
$transition-box-shadow: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
$transition-border: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
$transition-opacity: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

/* Media Query Ranges */
$xsmall-screen-up: 420px !default;
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$xlarge-screen-up: 1601px !default;

$xsmall-screen: 421px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$xlarge-screen: 1600px !default;

$xsmall-and-up: 'only screen and (min-width : #{$xsmall-screen-up})' !default; /* more than 360px */
$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default; /* more than 600px */
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default; /* more than 992px */
$extra-large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default; /* more than 1200px */
$xxlarge-and-up: 'only screen and (min-width : #{$xlarge-screen-up})' !default; /* more than 1600px */

$xsmall-and-down: 'only screen and (max-width : #{$xsmall-screen})' !default; /* 360px and less */
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default; /* 600px and less */
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default; /* 992px and less */
$large-and-down: 'only screen and (max-width : #{$large-screen})' !default; /* 1200px and less */
$xlarge-and-down: 'only screen and (max-width : #{$xlarge-screen})' !default; /* 1600px and less */

$small-only: 'only screen and (min-width : #{$xsmall-screen-up}) and (max-width : #{$small-screen})' !default; /* 361 to 600 */
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default; /* 601 to 992 */
$large-only: 'only screen and (min-width : #{$medium-screen-up}) and (max-width : #{$large-screen})' !default; /* 993 to 1200 */
$xlarge-only: 'only screen and (min-width : #{$large-screen-up}) and (max-width : #{$xlarge-screen})' !default; /* 1201 to 1600 */
