/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.animationsPanel label {
  justify-content: space-between;
  width: 100%;
}
.animationsPanel .title {
  display: flex;
  justify-content: space-between;
  line-height: 1rem;
}
.animationsPanel .scaleUp {
  display: block;
}
.animationsPanel .switchRow {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.animationsPanel .label {
  flex-basis: 100%;
  justify-content: space-between;
  margin-left: 0;
}
.animationsPanel .collapse {
  padding: 8px;
}
.animationsPanel h6 {
  margin-bottom: 0;
}

.collapseTitle {
  padding: 16px 0 8px;
}

.appearanceAnimationPanel label,
.disappearanceAnimationPanel label {
  justify-content: space-between;
  width: 100%;
}
.appearanceAnimationPanel .btnContainer,
.disappearanceAnimationPanel .btnContainer {
  align-items: flex-end;
  display: flex;
}
.appearanceAnimationPanel .btnContainer .removeBtn,
.disappearanceAnimationPanel .btnContainer .removeBtn {
  height: 24px;
  margin: 0 0 8px;
  min-height: 24px;
  min-width: 24px;
  padding: 0;
  width: 24px;
}
.appearanceAnimationPanel .btnContainer .removeBtn svg,
.disappearanceAnimationPanel .btnContainer .removeBtn svg {
  font-size: 24px;
}
.appearanceAnimationPanel .btnContainer .addBtnContainer,
.disappearanceAnimationPanel .btnContainer .addBtnContainer {
  display: flex;
  justify-content: flex-end;
}
.appearanceAnimationPanel .btnContainer .addBtnContainer .addBtn,
.disappearanceAnimationPanel .btnContainer .addBtnContainer .addBtn {
  height: 24px;
  margin: 0 0 8px;
  min-height: 24px;
  min-width: 24px;
  padding: 0;
  width: 24px;
}
.appearanceAnimationPanel .btnContainer .addBtnContainer .addBtn svg,
.disappearanceAnimationPanel .btnContainer .addBtnContainer .addBtn svg {
  font-size: 24px;
}