/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
.container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}
@media screen and (width <= 992px) {
  .container {
    padding: 16px;
  }
}
.container .innerContainer {
  height: max-content;
  padding: 48px 0;
}
.container .innerContainer.noPadding {
  padding: 0;
}
.container.noPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.withHeader {
  height: calc(100% - 72px);
  overflow-y: auto;
}
@media screen and (width <= 992px) {
  .withHeader {
    height: auto;
  }
}

.withBar {
  height: calc(100% - 68px);
  overflow-y: auto;
}
@media screen and (width <= 992px) {
  .withBar {
    height: auto;
  }
}

.withHeaderAndBar {
  height: calc(100% - 140px);
  overflow-y: auto;
}
@media screen and (width <= 992px) {
  .withHeaderAndBar {
    height: auto;
  }
}

.withHeaderAndBarAndPagination {
  height: calc(100% - 204px);
  overflow-y: auto;
}
@media screen and (width <= 992px) {
  .withHeaderAndBarAndPagination {
    height: auto;
  }
}

.withHeaderBarPaginationAndFilters {
  height: calc(100% - 256px);
  overflow-y: auto;
}
@media screen and (width <= 992px) {
  .withHeaderBarPaginationAndFilters {
    height: auto;
  }
}