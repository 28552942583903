/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* You can add global styles to this file, and also import other style files */ /* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
/* Helper function
Return null rather than throwing an error if index is outside list range. */
/* Different devices resolutions */
/* Proper images aspect ratio - 0.5625 or 9/16 */
/* MATERIAL ICONS FONT */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  src: url("libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.eot");
  src: url("libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.eot?#iefix") format("embedded-opentype"), url("libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/MaterialIcons-Regular.ttf") format("truetype");
}
/* OPUS FONTS */
@font-face {
  font-display: swap;
  font-family: "Nobel Light";
  font-style: normal;
  font-weight: 300;
  src: url("libs/shared/assets/src/lib/fonts/Nobel-Light.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/Nobel-Light.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/Nobel-Light.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Nobel Regular";
  font-style: normal;
  font-weight: normal;
  src: url("libs/shared/assets/src/lib/fonts/Nobel-Regular.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/Nobel-Regular.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/Nobel-Regular.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Nobel Bold";
  font-style: normal;
  font-weight: bold;
  src: url("libs/shared/assets/src/lib/fonts/Nobel-Bold.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/Nobel-Bold.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/Nobel-Bold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Avenir 100";
  font-style: normal;
  font-weight: 100;
  src: url("libs/shared/assets/src/lib/fonts/Avenir-100.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/Avenir-100.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/Avenir-100.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Avenir 300";
  font-style: normal;
  font-weight: 100;
  src: url("libs/shared/assets/src/lib/fonts/Avenir-300.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/Avenir-300.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/Avenir-300.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Avenir 500";
  font-style: normal;
  font-weight: 100;
  src: url("libs/shared/assets/src/lib/fonts/Avenir-500.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/Avenir-500.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/Avenir-500.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Avenir 700";
  font-style: normal;
  font-weight: 100;
  src: url("libs/shared/assets/src/lib/fonts/Avenir-700.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/Avenir-700.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/Avenir-700.ttf") format("truetype");
}
/* SOMEDAY FONTS */
@font-face {
  font-display: swap;
  font-family: "Museo Sans 100";
  font-style: normal;
  font-weight: 100;
  src: url("libs/shared/assets/src/lib/fonts/MuseoSans-100.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/MuseoSans-100.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/MuseoSans-100.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Museo Sans 300";
  font-style: normal;
  font-weight: 300;
  src: url("libs/shared/assets/src/lib/fonts/MuseoSans-300.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/MuseoSans-300.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/MuseoSans-300.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Museo Sans 500";
  font-style: normal;
  font-weight: 500;
  src: url("libs/shared/assets/src/lib/fonts/MuseoSans-500.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/MuseoSans-500.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/MuseoSans-500.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Museo Sans 700";
  font-style: normal;
  font-weight: 700;
  src: url("libs/shared/assets/src/lib/fonts/MuseoSans-700.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/MuseoSans-700.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/MuseoSans-700.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Trade Gothic Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("libs/shared/assets/src/lib/fonts/TradeGothic-Bold.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/TradeGothic-Bold.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/TradeGothic-Bold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  src: url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.eot");
  src: url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.ttf") format("truetype"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Regular.svg#SFProDisplay-Regular") format("svg");
}
@font-face {
  font-display: swap;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 500;
  src: url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.eot");
  src: url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.eot?#iefix") format("embedded-opentype"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.ttf") format("truetype"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Medium.svg#SFProDisplay-Medium") format("svg");
}
@font-face {
  font-display: swap;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 600;
  src: url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.eot");
  src: url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.eot?#iefix") format("embedded-opentype"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.woff2") format("woff2"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.woff") format("woff"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.ttf") format("truetype"), url("libs/shared/assets/src/lib/fonts/SF-Display/hinted-SFProDisplay-Semibold.svg#SFProDisplay-Semibold") format("svg");
}
html {
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

*:focus,
*:hover {
  outline: none;
}

#root {
  height: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.hidden {
  display: none;
  left: -9999px;
  position: absolute;
  top: -9999px;
  visibility: hidden;
}

.visuallyHidden {
  visibility: hidden;
}

.Visible {
  visibility: visible !important;
}

.Dimmed {
  opacity: 0.6;
}

.noData {
  color: #d5d5d5;
  padding: 48px 0;
}

.noSearchData {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.no-scroll {
  overflow-y: hidden;
}

.text-capitalize {
  text-transform: capitalize;
}

.canvas-container {
  background-color: #fff;
  margin: 16px auto;
  position: relative;
  user-select: none;
}

.dropzone {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.activeDrop {
  z-index: 9;
}
.activeDrop::after {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.54);
  z-index: 10;
  align-items: flex-start;
  border: 3px dashed #efefef;
  content: "Drag 'n' drop file for upload";
  display: flex;
  font-size: 10px;
  justify-content: center;
}

.Preloader {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.flexRow,
.SnappingOptionsForm .boxBorder {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.flexColumn {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.responsiveWarning {
  display: none;
}
@media only screen and (width <= 421px) {
  .responsiveWarning {
    align-self: center;
    background: rgba(255, 255, 255, 0.95);
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    position: fixed;
    z-index: 999;
  }
}

.alignStart {
  align-items: flex-start;
}

.smallTitle {
  font-size: 16px;
}
.smallTitle span {
  font-size: 16px;
  font-weight: 700;
}

button.opusIconBtn {
  border: 1px solid #efefef;
  border-radius: 0;
  height: 36px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  width: 36px;
}

.formControl {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
}
.formControl label[class*=" MuiInputLabel-outlined"] {
  font-weight: 500;
  transform: translate(10px, 10px) scale(1);
}
.formControl label[class*=" MuiInputLabel-outlined"][class*=" MuiInputLabel-shrink"] {
  transform: translate(10px, -6px) scale(0.75);
}
.formControl .MuiSelect-select {
  background-color: #fff;
  line-height: 20px;
  min-height: 20px;
  padding: 8px 32px 8px 10px;
}
.formControl div[class*=" MuiOutlinedInput"].MuiInputBase-root:not(.MuiAutocomplete-inputRoot),
.formControl div[class*=" MuiTablePagination-input"].MuiInputBase-root:not(.MuiAutocomplete-inputRoot) {
  height: 36px;
}
.formControl div[class*=" MuiOutlinedInput"].MuiOutlinedInput-adornedEnd:not(.MuiAutocomplete-inputRoot),
.formControl div[class*=" MuiTablePagination-input"].MuiOutlinedInput-adornedEnd:not(.MuiAutocomplete-inputRoot) {
  padding-right: 0;
}
.formControl div[class*=" MuiOutlinedInput"] fieldset[class^=PrivateNotchedOutline],
.formControl div[class*=" MuiTablePagination-input"] fieldset[class^=PrivateNotchedOutline] {
  border-color: #efefef;
  border-radius: 0;
  padding: 0 5px;
}
.formControl div[class*=" MuiOutlinedInput"]:not([class*=Mui-disabled])[class*=" Mui-focused"] fieldset[class^=PrivateNotchedOutline], .formControl div[class*=" MuiOutlinedInput"]:not([class*=Mui-disabled]):hover fieldset[class^=PrivateNotchedOutline],
.formControl div[class*=" MuiTablePagination-input"]:not([class*=Mui-disabled])[class*=" Mui-focused"] fieldset[class^=PrivateNotchedOutline],
.formControl div[class*=" MuiTablePagination-input"]:not([class*=Mui-disabled]):hover fieldset[class^=PrivateNotchedOutline] {
  border-color: #999;
  border-width: 1px;
}
.formControl div[class*=" MuiOutlinedInput"] input,
.formControl div[class*=" MuiTablePagination-input"] input {
  background-color: transparent;
  border: 0 none;
  line-height: 1.3rem;
  outline: none;
  padding: 8px 32px 8px 10px;
}
.formControl div[class*=" MuiOutlinedInput"] .MuiInputAdornment-positionEnd button,
.formControl div[class*=" MuiTablePagination-input"] .MuiInputAdornment-positionEnd button {
  padding: 8px;
}
.formControl div[class*=" MuiOutlinedInput"] .MuiInputAdornment-positionEnd button.MuiIconButton-edgeEnd,
.formControl div[class*=" MuiTablePagination-input"] .MuiInputAdornment-positionEnd button.MuiIconButton-edgeEnd {
  margin-right: 0;
}
.formControl .MuiFormHelperText-root {
  margin-left: 10px;
  margin-right: 10px;
}
.formControl .MuiSelect-icon {
  color: #333;
  top: calc(50% - 10px);
}
.formControl.MuiFormControl-root {
  margin: 16px 0 0;
}

.filtersSelectField {
  flex-basis: 33%;
  margin: 0 8px !important;
  max-width: 224px;
}
.filtersSelectField label[class*=" MuiInputLabel-outlined"] {
  font-weight: 500;
  transform: translate(10px, 10px) scale(1);
}
.filtersSelectField label[class*=" MuiInputLabel-outlined"][class*=" MuiInputLabel-shrink"] {
  transform: translate(10px, -6px) scale(0.75);
}
.filtersSelectField .MuiSelect-select {
  background-color: #fff;
  line-height: 20px;
  min-height: 20px;
  padding: 8px 32px 8px 10px;
}
.filtersSelectField div[class*=" MuiOutlinedInput"].MuiInputBase-root:not(.MuiAutocomplete-inputRoot),
.filtersSelectField div[class*=" MuiTablePagination-input"].MuiInputBase-root:not(.MuiAutocomplete-inputRoot) {
  height: 36px;
}
.filtersSelectField div[class*=" MuiOutlinedInput"].MuiOutlinedInput-adornedEnd:not(.MuiAutocomplete-inputRoot),
.filtersSelectField div[class*=" MuiTablePagination-input"].MuiOutlinedInput-adornedEnd:not(.MuiAutocomplete-inputRoot) {
  padding-right: 0;
}
.filtersSelectField div[class*=" MuiOutlinedInput"] fieldset[class^=PrivateNotchedOutline],
.filtersSelectField div[class*=" MuiTablePagination-input"] fieldset[class^=PrivateNotchedOutline] {
  border-color: #efefef;
  border-radius: 0;
  padding: 0 5px;
}
.filtersSelectField div[class*=" MuiOutlinedInput"]:not([class*=Mui-disabled])[class*=" Mui-focused"] fieldset[class^=PrivateNotchedOutline], .filtersSelectField div[class*=" MuiOutlinedInput"]:not([class*=Mui-disabled]):hover fieldset[class^=PrivateNotchedOutline],
.filtersSelectField div[class*=" MuiTablePagination-input"]:not([class*=Mui-disabled])[class*=" Mui-focused"] fieldset[class^=PrivateNotchedOutline],
.filtersSelectField div[class*=" MuiTablePagination-input"]:not([class*=Mui-disabled]):hover fieldset[class^=PrivateNotchedOutline] {
  border-color: #999;
  border-width: 1px;
}
.filtersSelectField div[class*=" MuiOutlinedInput"] input,
.filtersSelectField div[class*=" MuiTablePagination-input"] input {
  background-color: transparent;
  border: 0 none;
  line-height: 1.3rem;
  outline: none;
  padding: 8px 32px 8px 10px;
}
.filtersSelectField div[class*=" MuiOutlinedInput"] .MuiInputAdornment-positionEnd button,
.filtersSelectField div[class*=" MuiTablePagination-input"] .MuiInputAdornment-positionEnd button {
  padding: 8px;
}
.filtersSelectField div[class*=" MuiOutlinedInput"] .MuiInputAdornment-positionEnd button.MuiIconButton-edgeEnd,
.filtersSelectField div[class*=" MuiTablePagination-input"] .MuiInputAdornment-positionEnd button.MuiIconButton-edgeEnd {
  margin-right: 0;
}
.filtersSelectField .MuiFormHelperText-root {
  margin-left: 10px;
  margin-right: 10px;
}
.filtersSelectField .MuiSelect-icon {
  color: #333;
  top: calc(50% - 10px);
}

div[class^=MuiAutocomplete-popper] ul[class^=MuiAutocomplete-listbox] li[aria-selected=true] {
  background-color: #fff;
}

.Mui-disabled input[type=checkbox] + svg {
  opacity: 0.38;
}

.boxBorder,
.SnappingOptionsForm .boxBorder {
  border-bottom: 1px solid #efefef;
  padding: 16px;
}

/* Fix for Fabric.js text */
textarea[data-fabric-hiddentextarea] {
  position: fixed !important;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #efefef;
}

.logoWrapper {
  align-items: center;
  display: flex;
  min-height: 48px;
}
.logoWrapper .logo {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 16px 0 0;
  max-width: 112px;
  vertical-align: middle;
}

/*** TRANSITIONS ***/
/*** Use :global scoped keyframes for using inside SCSS modules ***/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* FADE */
.fade-enter,
.fade-leave {
  opacity: 0.01;
}

.fade-enter.fade-enter-active,
.fade-leave {
  opacity: 1;
}

.fade-enter.fade-enter-active,
.fade-leave.fade-leave-active {
  transition: opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
}

.canvas-fade-enter {
  opacity: 0;
}

.canvas-fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.canvas-fade-exit {
  opacity: 1;
}

.canvas-fade-exit-active {
  opacity: 0;
  transition: opacity 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* DIRECTIONAL */
.directional-enter,
.directional-leave {
  transform: translateY(100%);
}

.directional-enter.directional-enter-active,
.directional-leave.directional-leave-active {
  transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.directional-enter.directional-enter-active,
.directional-leave {
  transform: translateY(0);
}

.directional-leave.directional-leave-active {
  transform: translateY(-100%);
}

.canvas-directional-enter {
  transform: translateY(100%);
}

.canvas-directional-enter-active {
  transform: translateY(0%);
  transition: transform 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.canvas-directional-exit {
  transform: translateY(0%);
}

.canvas-directional-exit-active {
  transform: translateY(-100%);
  transition: transform 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.spaceAround {
  display: flex;
  justify-content: space-around;
}

.SnappingOptionsForm .boxBorder {
  padding: 12px 16px;
}
.SnappingOptionsForm .arrowIcon {
  top: 8px;
}