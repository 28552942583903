/* Colors */
/* Theme colors */
/* Sizes */
/* Theme colors */
/* Shadows */
/* Animation */
/* Media Query Ranges */
/* more than 360px */
/* more than 600px */
/* more than 992px */
/* more than 1200px */
/* more than 1600px */
/* 360px and less */
/* 600px and less */
/* 992px and less */
/* 1200px and less */
/* 1600px and less */
/* 361 to 600 */
/* 601 to 992 */
/* 993 to 1200 */
/* 1201 to 1600 */
.chip.round {
  border-radius: 50%;
}
.chip.default {
  border: 1px solid #efefef;
  border-radius: 0;
  font-size: 12px;
  line-height: 12px;
  padding: 0 12px;
}